
      * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
      }
      
      .navbar {
        background: linear-gradient(90deg, white 0%, white 100%);
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        top: 0;
        z-index: 999;
      }
      
      .navbar-container {
        display: flex;
        justify-content: center;
        height: 120px;
        font-weight: 600;
      }

      .logo{
        width: 100%;
    }
    .social{
        width: 100%; 
        margin: 0 auto;
        padding: 0.5rem 0.5rem;
    }
    #logos{
      width: 15%;
      padding: 1%;;
        display: flex;
        align-items: center;
        justify-content: center;
        list-style: none;
        text-align: center;
    }
      .container {
        z-index: 1;
        width: 100%;
        max-width: 1300px;
        margin-right: auto;
        margin-left: auto;
        padding-right: 5vw;
        padding-left: 5vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      
      .navbar-logo {
        color: rgb(58, 58, 58);
        cursor: pointer;
        text-decoration: none;
        font-size: 1rem;
        display: flex;
        width: 20%;
      }
      
      
      .nav-menu {
        width: 50%;
        display: flex;
        list-style: none;
        padding: 1%;
        justify-content: flex-end;
      }
      
      
      .nav-links:hover {
        border-bottom: 2px solid black;
      }
      
      .nav-links {
        color: black;
        display: flex;
        align-items: center;
        text-decoration: none;
        padding: 0.5rem 1rem;
        height: 100%;
      }
      
      .activated {
        color: black;
      }
      
      .fa-bars {
        color: black;
      }
      
      .menu-icon {
        display: none;
      }
      
      @media screen and (max-width: 1170px) {
        .NavbarItems {
          position: relative;
        }
        .logo{
          width: 130%;
      }
      .social{
        width: 80%;
       
      }
        .nav-menu {
          display: flex;
          flex-direction: column;
          width: 100%;
          position: absolute;
          top: 120px;
          left: -100%;
          opacity: 1;
         
        }
      
        .nav-menu.active {
          background-color: white;
          
          left: 0;
          opacity: 1;
          transition: all 0.6s ease;opacity: 0.9;
          z-index: 1;
          transition: all 0.5s ease;
        }
      
        .nav-links {
          text-align: center;
          padding: 2rem;
          width: 100%;
          display: table;
        }
      
        .nav-links:hover {
          color: black;
          font-size: 1.2rem;
          transition: all 0.3s ease;
        }
      
        .nav-item:hover {
          border: none;
        }
      
        .nav-item {
          width: 100%;
        }
      
        .menu-icon {
          display: block;
          font-size: 1.8rem;
          cursor: pointer;
        }
      }

      
      @media screen and (max-width: 800px) {

        .logo{
          width: 150%;
      }
      .social{
        width: 80%;
       
      }
      }

      @media screen and (max-width: 660px) {

        .logo{
          width: 180%;
      }
      .social{
        width: 90%;
       
      }
      }

      @media screen and (max-width: 540px) {

        .logo{
          width: 200%;
      }
      .social{
        width: 100%;
       
      }
      }

      @media screen and (max-width: 490px) {

        .logo{
          width: 220%;
      }
      .social{
        width: 110%;
       
      }
      }
      @media screen and (max-width: 400px) {

        .logo{
          width: 240%;
      }
      .social{
        width: 130%;
       
      }
      }