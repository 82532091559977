.about-section{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}
.about-img{
width: 40%;
margin-top: 1.6vh;
display: inline-block;
}
.about-img >img{
   width: 100%;; 
}
.about-text-container{
   vertical-align: top;
   text-align: center;
width: 40%;
display: inline-block;
}
.about-text{
width: 92%;margin:0 auto;
}
.about-text >h4{
   padding: 1.5%;text-align: justify;
   text-justify: inter-word;
}
.about-text >h1{
   padding: 1.5%;
   text-align: justify;
   text-justify: inter-word;
}

@media screen and (max-width: 1440px) {
   .about-img {
      width: 35vw;
   }

   .about-text-container {
      width: 55vw;
   }
}

@media screen and (max-width: 800px) {
   .about-img {
      width: 90vw;
  }

  .about-text-container {
      width: 90vw;
   }

  .about-section {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
   }
}

@media screen and (max-width: 660px) {

}