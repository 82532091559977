

.gallery {
 
    margin: 1%;
    border: 1px solid #ccc;
    width: 45%;
    display: inline-block;
  }
  .Portfolio-container{  width: 75%;;
    text-align: center;
    margin: 0 auto;
  
}
  .gallery:hover {
    border: 1px solid #777;
  }
  
  .gallery img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  
  .desc {
    padding: 15px;
    text-align: center;
  }
  @media screen and (max-width: 1170px) {
    .Portfolio-container {
      width: 85%;
    }
    .gallery {
      width: 45%;
    }
  }

  @media screen and (max-width: 660px) {
    h5 {
      font-size: 0.6rem;
    }

    .Portfolio-container {
        width: 90vw;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: stretch;
    }
  }

  
  @media screen and (max-width: 540px) {
    .Portfolio-container {
        width: 90vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .gallery {
      width: 90%;
    }

    h5 {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 540px) {
    h5 {
      font-size: 0.7rem;
    }
  }