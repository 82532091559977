* {
    margin: 0;
    padding: 0;
}
.hero-image > img{
    max-width: 100%;
    z-index: -1;
}
.hero-projects-img  .projects-img{
    width: 100%;
    height: 150px;
    object-fit: cover;
}
.hero-projects {
    margin-top: 1%;
   align-items: center;
}
.hero-projects-text{
    text-align: center;
    width: 50%;
    margin:0 auto;
    padding: 1rem;
}
.hero-projects-img{
    position: relative;
    display: inline-block;   width: 25%;
}
.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgb(243, 243, 243);
  }
  .hero-projects-img:hover .overlay {
    opacity: 0.9;
  }
  .text {
    color: black;
    font-size: 20px;
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }

  @media screen and (max-width: 800px) {
    .hero-projects-img {
        position: relative;
        max-width: 44vw;
        min-width: 35vw;
        display: inline-block;
        width: auto;
        padding-bottom: 2vh;
    }

    .hero-projects {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: stretch;
    }

    .hero-projects-href {       
        display: flex;
        flex-direction: column;
    }

    .overlay {
        opacity: 1;
        background-color: transparent;
        position: inherit;
        border: 1px solid #777;
    }

    .text {
        font-weight: 700;
        text-align: center;
        position: inherit;
        font-size: inherit;
        width: 100%;
        transform: none;
    }
    
    .hero-projects-text {
        padding: 1rem 0 0.5rem 0;
    }
  }

  @media screen and (max-width: 660px) {
    h1 {
        font-size: 1.2rem;
    }
  }

  @media screen and (max-width: 400px) {
    .hero-projects {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .hero-projects-img {
        width: 85vw;
        max-width: 85vw;
    }
  }
