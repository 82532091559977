.Project-container-text{
    padding:1.5%;
    width: 35%;
    display: inline-block;
    vertical-align: top;margin-top: 1%;
}
.Project-container-text>h4{
    
    text-align:start;
}
.Project-container-img{
    width: 100%;
    display: inline-block;
}
.Project-container{
    
   text-justify: inter-word;
   justify-items: center;
    align-items: center;
    text-align: center;
}
.Project-container-img-1{
    width: 30%;
    display: inline-block;
    margin-top: 1%;
}
.Project-container-img>img{
    width: 100%;
    margin-top: 1%;
}

.Project-container-img-1>img{
    width: 100%;
}
.Project-container-bottom{
    padding:1.5%;
    width: 100%;
    display: inline-block;
    text-justify: inter-word;
    justify-items: center;
     align-items: center;
     text-align: center;
 background-color: rgb(194, 194, 194);
    
}
#Project-container-bottom{
    padding:1.5%;
    width: 75%;
    display: inline-block;
    vertical-align: top;
    text-align:start;
}

.etap-wspolpracy{
    margin-right: 1rem;
    width: 1.4rem;
    float: left;
    margin-left: 1rem;
}


@media screen and (max-width: 1170px) {
    .Project-container-text {
        width: 45%;
    }
}

@media screen and (max-width: 800px) {
    .Project-container {
        display: flex;
        flex-direction: column;    
    }

    .Project-container-text {
        width: 90%;
    }

    #Project-container-bottom {
        width: 90%;
    }
    
    .Project-container-img-1 {
        width: 75%;
    }

    .Project-container-img-1>img {
        height: 90vw;
        transform: rotate(-90deg);
        width: auto;
    }

    .Project-container-text>h4 {
        font-size: 1rem;
    }
}