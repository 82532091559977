.Ogrod_wsrod_sosen-text{
    width: 30%;
    display: inline-block;
    text-align: justify;
   text-justify: inter-word;
   vertical-align: bottom;
}
#Ogrod_wsrod_sosen-text{
     margin: 1%;
    margin-right: 5%; 
}

.Ogrod_wsrod_sosen-container{
    justify-items: center;
    align-items: center;
    text-align: center;
    display: inline-block; 
    margin-top: 1%;
}
.Ogrod_wsrod_sosen-img .carousel-img{
    height: auto;
    width: 100%;
}

.Ogrod_wsrod_sosen-img{
    width: 50vw;
    height: auto;
    /* width: 50%;
    display: inline-block; */
}

.projekty-gallery-img>img{
    width: 100%;
    display: inline-block;
}

.projekty-gallery-img{
    display: inline-block;
    width:25%;
    cursor: pointer;
    margin:1%;
  
}
.projekty-gallery{
text-align: center;
margin-top: 5%;
}
.projekt-upper{    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
}

.slide-buttons{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.slide-button{
    background-color: gray;
    border: none;
    padding: 5px 15px 5px 15px;
    margin: 4px 2px;
}

@media screen and (max-width: 1240px) {
    .projekt-upper {
        flex-direction: column;
        align-items: center;
    }
    .Ogrod_wsrod_sosen-text {
        width: 80%;
        text-align: center;
    }
    

    .Ogrod_wsrod_sosen-img {
        width: 80vw;
    }

    .alice-carousel {
        width: auto;
    }
}

@media screen and (max-width: 800px) {
    .projekty-gallery-img {
        width: 40%;
    }
}