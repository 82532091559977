.footer-container{
    font-size: small;
    text-align: center;
    margin-top: 5%;
}
a{
    text-decoration: none;
    color: black;
}
.footer-container > h4{
    margin:1%;
}
.cloverit:hover {
    color: rgb(112, 224, 112)
}
@media screen and (max-width: 660px) {
    h4 {
        font-size: 0.65rem;
    }
  }
  @media screen and (max-width: 490px) {
    h4 {
        font-size: 0.55rem;
    }
  }