.Contact-container{
     text-align: center;
     padding: 1%;
     margin-top: 3%;
}
.Contact-container-dane{
    padding: 2%;
    display: inline-block;
    -webkit-box-shadow: 41px 21px 100px -41px rgba(66, 68, 90, 1);
-moz-box-shadow: 41px 21px 100px -41px rgba(66, 68, 90, 1);
box-shadow: 41px 21px 100px -41px rgba(66, 68, 90, 1);
border-radius: 12px;
    width: 30%;
    vertical-align: top;
}
.Contact-container-form{
    display: inline-block;
    width: 50%; 
    padding: 2%;
}

.contact-icons{
    margin:1%;
    display: inline-block;
    width: 20%;
}
.contact-icons>img{
    width: 40%;
    display: inline-block;
}
.contact-icons-text{
    margin:1%;
    text-align: left;
    display: inline-block;
    vertical-align:top;
    width: 70%;
}


.app-form{
    display: inline-block;
   text-align: left;
    width: 90%;
    
}
.form-line{
    padding: 1%;
    text-align: left;
}
  .app-form-control {
    width: 100%;
    padding: 10px 0;
    background: none;
    border: none;
    border-bottom: 1px solid #666;
    color: black;
    font-size: 14px;
    /* text-transform: uppercase; */
    outline: none;
    transition: border-color .2s;
  }
  .app-form-control::placeholder {
    font-size: 15px;
  }
  .app-form-button{
   width: 20%;
   font-size: 1rem;
   padding: 2%;
   border:none;
   border-radius:10px;
   box-shadow: 0px 0px 2px 2px rgb(255, 255, 255);

  }
  .app-form-button:hover{
background-color: #666;
color: white;
  }
  .app-form-control:hover{
    border-bottom: 2px solid rgb(172, 172, 172);
  }
  input.input-error,
  select.input-error {
    border-color: #fc8181;
  }
    
  button:disabled {
    opacity: 0.35;
  }

  input:focus,
  select:focus {
    border-color: #4299e1;
  }
  
  input::placeholder,
  select::placeholder {
    color: #a0aec0;
  }

  .error {
    color: #fc8181;
    font-size: 0.75rem;
    text-align: left;
    margin-top: 0.25rem;
  }

  @media screen and (max-width: 912px) {
    .Contact-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    } 

    .Contact-container-form {
      width: 90%;
    }

    .Contact-container-dane {
      width: 75%;
      margin-bottom: 5%;
    }

    .Contact-container-dane>h4 {
      font-size: 1rem;
    }

    .contact-icons-text>h4 {
      font-size: 1rem;
    }
    
    .app-form-button {
      font-size: 1rem;
      padding: 2%;
    }
  }

  @media screen and (max-width: 400px) {
    .app-form-button {
      font-size: 0.9rem;
      width: 100%;
    }
  }